'use client'

import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import NotificationProvider from '../components/UI/NotificationProvider'
import { StatusPopupContextProvider } from '@utils/StatusPopupContext'

export default function Providers({ children }) {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            retry: false,
            staleTime: 1000 * 3,
          },
        },
      }),
  )

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <StatusPopupContextProvider>
          <NotificationProvider>{children}</NotificationProvider>
        </StatusPopupContextProvider>
      </QueryClientProvider>
    </>
  )
}
