import React, { useMemo } from 'react'
import { notification, message as AntMessage } from 'antd'
import { nanoid } from 'nanoid'
import { AiOutlineClose } from 'react-icons/ai'
import Flex from '@ui/Flex'
import Box from '@ui/Box'

export interface NotificationInterface {
  showSuccess: (
    message: string,
    description?: React.ReactNode,
    options?: { duration: number },
  ) => string
  showError: (message: string, description?: React.ReactNode) => string
  showLoading: (message: string, description?: React.ReactNode) => string
  destroy: (key?: string) => void
}

const NotificationContext = React.createContext<NotificationInterface>({
  showSuccess: () => '',
  showError: () => '',
  showLoading: () => '',
  destroy: () => {},
})

export const useNotification = () => {
  return React.useContext(NotificationContext)
}

type Props = {
  children: React.ReactNode
}

const NotificationProvider = ({ children }: Props) => {
  const [api, contextHolder] = AntMessage.useMessage()

  const value = useMemo(
    () => ({
      showSuccess: (
        message: string,
        description?: string,
        options?: { duration: number },
      ) => {
        const key = nanoid()

        api.open({
          key,
          type: 'success',
          duration: options?.duration === undefined ? 5 : options.duration,
          onClick: () => {
            api.destroy(key)
          },
          content: (
            <>
              {message} {description}{' '}
            </>
          ),
        })

        return key
      },
      showError: (message: string, description?: string) => {
        const key = nanoid()

        api.open({
          key,
          type: 'error',
          content: (
            <>
              {message} {description}{' '}
            </>
          ),
          onClick: () => {
            api.destroy(key)
          },
          duration: 5,
        })

        return key
      },
      showLoading: (message: string, description?: string) => {
        const key = nanoid()

        api.open({
          key,
          type: 'loading',
          content: (
            <>
              {message} {description}{' '}
            </>
          ),
          onClick: () => {
            api.destroy(key)
          },
          duration: 0,
        })

        return key
      },

      destroy: (key?: string) => {
        api.destroy(key)
      },
    }),
    [api],
  )
  return (
    <NotificationContext.Provider value={value}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

export default NotificationProvider
