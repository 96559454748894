'use client'

import React, { useRef, useState } from 'react'
import { useServerInsertedHTML } from 'next/navigation'
import { ServerStyleSheet, StyleSheetManager } from 'styled-components'
import { createCache, extractStyle, StyleProvider } from '@ant-design/cssinjs'
import * as process from 'process'

export default function StyledComponentsRegistry({
  children,
}: {
  children: React.ReactNode
}) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet())
  const [cache] = useState(() => createCache())
  const isServerInserted = useRef(false)

  useServerInsertedHTML(() => {
    if (!isServerInserted.current) {
      isServerInserted.current = true
      const styles = styledComponentsStyleSheet.getStyleElement()
      styledComponentsStyleSheet.instance.clearTag()
      return (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `</script>${extractStyle(cache)}<script>`,
            }}
          />
          {styles}
        </>
      )
    }

    return null
  })

  if (typeof window !== 'undefined') return <>{children}</>

  if (process.env.NODE_ENV === 'development') {
    return <>{children}</>
  }

  return (
    <StyleSheetManager sheet={styledComponentsStyleSheet.instance}>
      <StyleProvider cache={cache}>{children}</StyleProvider>
    </StyleSheetManager>
  )
}
