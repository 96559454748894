'use client'

import React, { useMemo, useState } from 'react'
import { Button, Modal, Result } from 'antd'
import { StatusPopupContext } from '@utils/useStatusPopup'

export const StatusPopupContextProvider = ({ children }) => {
  const [modal, setModal] = useState<{
    title: string
    text?: string
    status: 'success' | 'error'
  } | null>(null)

  const showSuccess = (title: string, text?: string) => {
    setModal({
      title,
      text,
      status: 'success',
    })
  }

  const showError = (title: string, text?: string) => {
    setModal({
      title,
      text,
      status: 'error',
    })
  }

  const onClose = () => {
    setModal(null)
  }

  const value = useMemo(() => ({ showSuccess, showError }), [showSuccess, showError])

  return (
    <StatusPopupContext.Provider value={value}>
      {!!modal && (
        <Modal open={!!modal} onCancel={onClose} footer={null}>
          <Result
            status={modal?.status}
            title={modal?.title}
            subTitle={modal?.text}
            extra={[
              <Button key="1" type="default" onClick={onClose}>
                Close
              </Button>,
            ]}
          />
        </Modal>
      )}
      {children}
    </StatusPopupContext.Provider>
  )
}
