import React, { useContext } from 'react'

interface StatusPopupContextInterface {
  showSuccess: (message: string, text?: string) => void
  showError: (message: string, text?: string) => void
}

export const StatusPopupContext = React.createContext<StatusPopupContextInterface>({
  showError: () => {},
  showSuccess: () => {},
})

export const useStatusPopup = () => {
  return useContext(StatusPopupContext)
}
